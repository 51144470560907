/* eslint-disable no-undef */
import axios from 'axios'
require('dotenv').config()
import router from '../../router'
import Vue from 'vue'

/**
 * default headers shared by  configs
 * @type Object
 */
let baseurl = process.env.VUE_APP_API_URL
let partner_id = process.env.VUE_APP_PARTNER_ID

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  partner_id: partner_id,
}
/**
 * Main api config for ops-portal
 * @type Object axios configs
 * @TODO env url

 */
const api = axios.create({ baseURL: baseurl, headers: defaultHeaders })

api.interceptors.request.use(res => {
    if (localStorage.getItem('tehecaAuth') !== null) {
      const auth = JSON.parse(localStorage.getItem('tehecaAuth'))
      res.headers = {
        Authorization: `Bearer: ${auth.token}`,
        'X-Country-Code': 'UG',
        'X-Email': auth.user.email,
      }
    }
    return res
  },
  error => {
    if (error.response.status === 401) {
      if (localStorage.getItem('tehecaAuth') !== null) {
        localStorage.removeItem('tehecaAuth')
        router.push({ path: '/login' })
      }
    }
   return Promise.reject(error)
  }
)

api.interceptors.response.use(res => res,
  error => {
    if (error.response.status === 401) { 
      Vue.swal({
        title: 'Session Expired',
        text: 'Your session has expired. Would you like to be redirected to the login page?',
        type: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#41b882',
        cancelButtonColor: '#ff7674',
        closeOnConfirm: false,
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem('tehecaAuth')
          router.push({ path: '/login' })
        } 
      })
    }
    
    return error
  })

export { api }
