<template>
    <div class="add-nurse">
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="720px">
                <v-card :loading="getLoadingState" :disable="getLoadingState">
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="nurseProfile.first_name" label="First name*" required
                                            :rules="nameRules" />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="nurseProfile.last_name" label="Last name"
                                            :rules="nameRules" />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="nurseProfile.email" label="Email" />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="nurseProfile.phone_number" label="Phone number*" type="tel"
                                            required :rules="phoneRules" hint="256793400004" :counter="14" />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="nurseProfile.other_number" label="Other number" type="tel"
                                            hint="256793400004" :counter="14" />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="nurseProfile.date_of_birth" label="Date of birth"
                                                    v-on="on" />
                                            </template>
                                            <v-date-picker v-model="nurseProfile.date_of_birth" color="green lighten-1"
                                                @input="menu2 = false" />
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="nurseProfile.school" label="School attended" single />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-autocomplete v-model="nurseProfile.education_level" :items="[
                                            'Certificate',
                                            'Diploma',
                                            'Degree',
                                            'Masters',
                                            'PhD',
                                            'Others',
                                        ]" label="Highest education level" single />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-autocomplete v-model="nurseProfile.qualification" :rules="nameRules"
                                            label="Qualifications" editable :items="[
                                                'Registered Bachelor of Science Nursing',
                                                'Registered Bachelor of Science Midwifery',
                                                'Registered Masters of Science Nursing',
                                                'Registered Health Tutor-Nursing',
                                                'Registered Health Tutor- Midwifery',
                                                'Registered Public Health Nurse',
                                                'Registered Comprehensive Nurse',
                                                'Registered Nurse',
                                                'Registered Midwife',
                                                'Registered Mental Health Nurse',
                                                'Registered Paediatric Nurse',
                                                'Enrolled Nurse',
                                                'Enrolled Midwife',
                                                'Enrolled Mental Health Nurse',
                                                'Enrolled Comprehensive Nurse',
                                                'Community Health Worker',
                                                'Village Health Team',
                                                'Others',
                                            ]" single />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="nurseProfile.certificate_no"
                                            label="Nursing certificate number" type="text" />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="nurseProfile.graduation_year" label="Graduation Year"
                                            type="number" :counter="4" :length="4" />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="nurseProfile.work_place" label="Most recent work place" />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="nurseProfile.pastPosition"
                                            label="Most recent work position" />
                                    </v-col>
                                    <v-col cols="12">
                                        <vuetify-google-autocomplete id="address" v-model="nurseProfile.address"
                                            append-icon="mdi-search" placeholder="Start typing" label="Place of residence"
                                            clearable="clearable" types="address" :enable-geolocation="true"
                                            :place-name="true" country="UG" @placechanged="getAddressData" />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-autocomplete v-model="nurseProfile.gender" :items="['Male', 'Female', 'N/A']"
                                            label="Gender" single />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-autocomplete v-model="nurseProfile.availability"
                                            :items="['Full time', 'Night Time', 'Day Time', 'Weekends']" label="Availability"
                                            single  />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-autocomplete v-model="nurseProfile.work_status" :items="[
                                            'Student',
                                            'Internship',
                                            'Employed',
                                            'Unemployed',
                                            'Others',
                                        ]" label="Work status" single value="nurseProfile.work_status"/>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="nurseProfile.ref_name" label="Referee name" />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="nurseProfile.ref_email" label="Referee email" />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="nurseProfile.ref_phone_number" label="Referee phone number"
                                            :counter="14" />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="nurseProfile.join_date" label="Sign up date"
                                                    v-on="on" value="nurseProfile.join_date"/>
                                            </template>
                                            <v-date-picker v-model="nurseProfile.join_date" date-format="DD-MM-YYYY"
                                                @input="menu3 = false" />
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <small>*indicates required field</small>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="red darken-1" text @click="closeDialog()">
                            Close
                        </v-btn>
                        <v-btn v-if="isEditing" color="green darken-1" text :disabled="getLoadingState"
                            @click="submitUpdateProfile()">
                            Update
                        </v-btn>
                        <v-btn v-else color="green darken-1" text :disabled="getLoadingState" @click="addNurseProfile()">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>

                <v-dialog v-model="getLoadingState" hide-overlay persistent width="300">
                    <v-card color="primary" dark>
                        <v-card-text class="pt-3">
                            Please wait...
                            <v-progress-linear indeterminate color="white" class="mb-0" />
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-dialog>
        </v-row>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "AddNurseCard",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        isEditing: {
            type: Boolean,
            default: true,
        },
        nurse: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {
            lazy: false,
            valid: false,
            date: new Date(),
            menu: false,
            modal: false,
            menu2: false,
            menu3: false,
            ld: true,

            emailRules: [
                (v) => !!v || "E-mail is required",
                (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
            ],
            nameRules: [(v) => !!v || "Field is required"],
            phoneRules: [(v) => !!v || "Phone number is required"],
            nurseProfile: {
                first_name: "",
                last_name: "",
                ref_phone_number: "",
                ref_email: "",
                ref_name: "",
                work_status: "Unemployed",
                address: "",
                availability: "Full time",
                gender: "",
                pastPosition: "",
                work_place: "",
                graduation_year: "",
                certificate_no: "",
                qualification: "",
                school: "",
                date_of_birth: "",
                other_number: "",
                phone_number: "",
                education_level: "",
                latitude: 0.0,
                longitude: 0.0,
                join_date: moment().format(),
                email: "",
            },
        };
    },
    computed: {
        ...mapGetters([
            "listItem",
            "getLoadingState",
            "isInternetConnected",
            "getSelectedId",
        ]),
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        formTitle: {
            get() {
                return this.title;
            },
            set(title) {
                this.$emit("input", title);
            },
        },
        submitAction: {
            get() {
                return this.isEditing;
            },
            set(isEditing) {
                this.$emit("input", isEditing);
            },
        },
        today() {
            let endDate = new Date(
                this.date.getFullYear(),
                this.date.getMonth() + 1,
                1
            );
            return endDate.toISOString().slice(0, 10);
        },
    },

    watch: {
        dialog() {
            this.nurseProfile = Object.assign({}, this.nurse);
            if (this.isEditing) {
                this.getDataFromServer();
            }
        },
    },
    methods: {
        closeDialog() {
            this.dialog = false;
        },
        getAddressData(addressData, placeResultData) {
            this.nurseProfile.address = addressData.name;
            this.nurseProfile.latitude = addressData.latitude;
            this.nurseProfile.longitude = addressData.longitude;
        },
        getDataFromServer() {
            let joinDate = this.nurse.join_date
                ? new Date(this.nurse.join_date)
                : moment();
            let dob = this.nurse.date_of_birth
                ? new Date(this.nurse.date_of_birth)
                : moment();
            // Format it and save to vue data property

            this.nurseProfile.join_date = this.frontEndDateFormat(joinDate);
            this.nurseProfile.date_of_birth = this.frontEndDateFormat(dob);
        },
        frontEndDateFormat(date) {
            return moment(date).format("LL");
        },
        backEndDateFormat(date) {
            return moment(new Date(date)).format("LL");
        },
        async addNurseProfile() {
            if (!this.$refs.form.validate()) {
                this.$toast.error("Some fields are not filled");
                return;
            }
            const payload = {
                nurse: {
                    first_name: this.nurseProfile.first_name,
                    last_name: this.nurseProfile.last_name,
                    username:
                        this.nurseProfile.first_name +
                        " " +
                        this.nurseProfile.last_name,
                    email: this.nurseProfile.email,
                    phone_number: this.nurseProfile.phone_number,
                    other_number: this.nurseProfile.other_number,
                    address: this.nurseProfile.address,
                    date_of_birth: this.nurseProfile.date_of_birth,
                    verified: false,
                    active: true,
                    gender: this.nurseProfile.gender,
                    ref_phone_number: this.nurseProfile.ref_phone_number,
                    ref_email: this.nurseProfile.ref_email,
                    ref_name: this.nurseProfile.ref_name,
                    employment_status: this.nurseProfile.work_status,
                    availability: this.nurseProfile.availability,
                    pastPosition: this.nurseProfile.pastPosition,
                    work_place: this.nurseProfile.work_place,
                    certificate_no: this.nurseProfile.certificate_no,
                    qualification: this.nurseProfile.qualification,
                    school: this.nurseProfile.school,
                    education_level: this.nurseProfile.education_level,
                    latitude: this.nurseProfile.latitude,
                    longitude: this.nurseProfile.longitude,
                    graduation_year: this.nurseProfile.graduation_year,
                    profile_picture: "",
                    profileComplete: false,
                    online: false,
                    work_status: this.nurseProfile.work_status,
                    join_date:
                        this.nurseProfile.join_date || moment().format(),
                },
            };

                try {
                    const response = await this.$store.dispatch(
                        "createNurseProfile",
                        payload
                    );
                    if (response.data.status === "success") {
                        this.$toast.success("Nurse profile created", {
                            color: "success",
                            queueable: true,
                            timeout: 5000,
                        });
                        this.dialog = false;
                    } else if (response.data.status === "failed") {
                        this.$toast.error("Nurse profile saving failed");
                    }
                } catch (e) {
                    this.$toast.error("An error occured while processing the request");
                }
            
        },

        async submitUpdateProfile() {
            this.backEndDateFormat(this.nurseProfile.join_date);
            this.backEndDateFormat(this.nurseProfile.date_of_birth);

                try {
                    const response = await this.$store.dispatch(
                        "updateNurseProfile",
                        this.nurseProfile
                    );
                    if (response.data.status === "success") {
                        this.$toast.success("Nurse profile updated", {
                            color: "success",
                            queueable: true,
                            timeout: 5000,
                        });
                        this.dialog = false;
                    } else if (response.data.status === "failed") {
                        this.$toast.error(response.data.error);
                    }
                } catch (e) {
                    this.$toast.error(e.response.data.error);
                }
           
        },
    },
};
</script>
<style scoped lang="scss"></style>