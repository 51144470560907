import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import { store } from '@/store'
import InfiniteLoading from 'vue-infinite-loading'
import './registerServiceWorker'
import UUID from 'vue-uuid'
import VueSweetalert2 from 'vue-sweetalert2'
import vuetify from './plugins/vuetify'
import filters from './filters'
import VueHtmlToPaper from 'vue-html-to-paper'
import { options } from './helpers/printOptions'
import 'sweetalert2/dist/sweetalert2.min.css'

require('dotenv').config()
Vue.use(InfiniteLoading)
Vue.config.productionTip = false
Vue.use(filters)
Vue.use(VueHtmlToPaper, options)
Vue.use(UUID)
Vue.use(VueSweetalert2)
/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  vuetify,
  components: {
    App,
  },
  template: '<App/>',
})
